// Misc.
	$misc: (
		z-index-base:	10000
	);

// Duration.
	$duration: (
		navPanel: 0.5s
	);

// Size.
	$size: (
		navPanel: 275px
	);

// Font.
	$font: (
	);

// Palette.
	$palette: (
		'black': #000,
		'blue':rgb(70, 189, 211),
		'dark': #474d55,
		'gray': #888,
		'green': #488E4C,
		'orange': #E4A001,
		'red': #C0272D,
		'silver': #DDD,
		'white': #fff,
	);