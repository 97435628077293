@import 'libs/vars';
@import 'libs/functions';
@import 'libs/mixins';
@import 'libs/skel';

/*
	Zenith by Pixelarity
	pixelarity.com @pixelarity
	License: pixelarity.com/license
*/

/* Basic */

	.pennant {
		.icon {
			&:before {
				font-size: 2.4em;
			}
		}
	}

/* Wrappers */

	#header-wrapper {
		background: url('../../images/banner.jpg');
		background-size: cover;
		-ms-behavior: url('assets/js/ie/backgroundsize.min.htc');
	}

	#header-wrapper-ie8 {
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=75)";
		filter: alpha(opacity = 75);
		background: rgb(29, 32, 38);
	}

	#header-wrapper, #footer-wrapper {
		&:before {
			display: none;
		}
	}

/* Header */

	#header .inner {
		&:before {
			background-image: none;
		}
	}

/* Nav */

	.dropotron {
		background: rgb(73, 79, 88);

		&:after {
			display: none;
		}
	}